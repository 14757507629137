<template>
  <v-form v-model="valid" class="campaign-form" ref="form" lazy-validation>
    <general-card class="pa-5 mt-5">
      <div class="text-overline mb-2">Update Profile</div>
      <h6 class="mb-2">User Name</h6>
      <v-text-field
        dense
        outlined
        label="User Name"
        class="mb-4"
        v-model="name"
        required
        :rules="[(v) => !!v || 'Name is required!']"
      ></v-text-field>

      <h6 class="mb-2">User Email</h6>
      <v-text-field
        dense
        outlined
        label="Email"
        class="mb-4"
        v-model="email"
        type="email"
        required
        :rules="[(v) => !!v || 'Email is required!']"
      ></v-text-field>

      <h6 class="mb-2">User Phone Number</h6>
      <v-text-field
        dense
        outlined
        label="Phone Number"
        class="mb-4"
        v-model="phone_number"
        required
        :rules="[(v) => !!v || 'Phone is required!']"
      ></v-text-field>
      <h6 class="mb-2">Timezone</h6>
      <v-autocomplete
        v-model="timezone"
        :items="timezones"
        outlined
        dense
        chips
        deletable-chips
        small-chips
        label="Timezone"
      ></v-autocomplete>

      <v-card-actions class="d-flex justify-end pt-16">
        <v-btn color="warning" @click="handleSubmit">Submit</v-btn>
      </v-card-actions>
    </general-card>
  </v-form>
</template>

<script>
import GeneralCard from "./../Layouts/Cards/GeneralCard.vue";

export default {
  data: () => ({
    valid: true,
    name: "",
    email: "",
    phone_number: "",
    timezone: "",
  }),
  components: {
    GeneralCard,
  },
  created() {
    this.getStaticDspData();
  },
  methods: {
    async getStaticDspData() {
      try {
        await this.$store.dispatch("dsp/getStaticDspData");
      } catch (error) {
       this.error = error
      }
    },
    async validate() {
      await this.$refs.form.validate();
    },
    async handleSubmit() {
      await this.validate();
      if (!this.valid) return;

      this.updateUser();
    },
    updateUser() {
      let body = {
        name: this.name,
        email: this.email,
        phone_number: this.phone_number,
        timezone: this.timezone,
      }

      this.$emit("updateUser", body);
    },
  },
  computed: {
    timezones() {
      return this.$store.state.dsp.static_dsp_data.timezones;
    },
    user(){
        return this.$store.state.authenticated_user;
    }
  },
  mounted(){
    this.name = this.user.name;
    this.email = this.user.email;
    this.phone_number = this.user.phone_number;
    this.timezone = this.user.timezone;
  },
};
</script>