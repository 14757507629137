<template>
  <v-form v-model="valid" class="campaign-form" ref="form" lazy-validation>
    <general-card class="pa-5 mt-5">
      <div class="text-overline mb-2">Update Password</div>

      <h6 class="mb-2">Current Password</h6>
      <v-text-field
        dense
        autocomplete="off"
        outlined
        type="password"
        class="mb-4"
        v-model="current_password"
        :rules="[rules.required]"
      ></v-text-field>

      <h6 class="mb-2">New Password</h6>
      <v-text-field
        dense
        outlined
        autocomplete="off"
        class="mb-4"
        :rules="[rules.required, rules.min]"
        v-model="new_password"
        type="password"
      ></v-text-field>

      <h6 class="mb-2">Confirm Password</h6>
      <v-text-field
        dense
        outlined
        autocomplete="off"
        type="password"
        class="mb-4"
        v-model="password_confirmation"
        required
        :rules="[rules.required, rules.min]"
      ></v-text-field>

      <v-card-actions class="d-flex justify-end pt-16">
        <v-btn color="warning" @click="handleSubmit">Submit</v-btn>
      </v-card-actions>
    </general-card>
  </v-form>
</template>

<script>
import GeneralCard from "./../Layouts/Cards/GeneralCard.vue";

export default {
  data: () => ({
    valid: true,
    current_password: "",
    new_password: "",
    password_confirmation: "",
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
  }),
  components: {
    GeneralCard,
  },
  methods: {
    async validate() {
      await this.$refs.form.validate();
    },
    async handleSubmit() {
      await this.validate();
      if (!this.valid) return;

      if (this.new_password !== this.password_confirmation) {
        alert("Passwords do not match!");
        return;
      }

      this.updatePassword();
    },
    updatePassword() {
      let body = {
        current_password: this.current_password,
        password: this.new_password,
        password_confirmation: this.password_confirmation,
      };

      this.$emit("UpdatePassword", body);
    },
  },
};
</script>