<template>
  <div>
    <v-row>
      <v-col cols="6">
        <profile @updateUser="updateUser($event)"></profile>
      </v-col>

      <v-col cols="6">
        <update-password
          @UpdatePassword="UpdatePassword($event)"
        ></update-password>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Profile from "../../components/Users/Profile.vue";
import UpdatePassword from "../../components/Users/UpdatePassword.vue";

export default {
  data: () => ({
    error: null,
  }),
  components: {
    Profile,
    UpdatePassword,
  },
  methods: {
    async UpdatePassword(body) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("updateAuthenticatedUserPassword", body);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Password updated successfully!",
          group: "success",
        });
      } catch (error) {
        this.$notify({
          title: error.error,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },

    async updateUser(body) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("updateAuthenticatedUserProfile", body);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Profile updated successfully!",
          group: "success",
        });
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },
  },
};
</script>